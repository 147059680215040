<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 대시보드</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/user')"
        >
          <v-icon dark size="30"> mdi-account </v-icon>
          <h2>&nbsp;사용자 관리</h2>
        </v-btn>
      </v-col>
      <!-- <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/permission')"
        >
          <v-icon dark size="30"> mdi-account-group </v-icon>
          <h2>&nbsp;회원그룹 관리</h2>
        </v-btn>
      </v-col> -->
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/company')"
        >
          <v-icon dark size="30"> mdi-domain </v-icon>
          <h2>&nbsp;회사 등록 관리</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/product')"
        >
          <v-icon dark size="30"> mdi-archive-cog </v-icon>
          <h2>&nbsp;제품 등록 관리</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/contract')"
        >
          <v-icon dark size="30"> mdi-briefcase </v-icon>
          <h2>&nbsp;프로젝트 관리</h2>
        </v-btn>
      </v-col>
      <!-- <v-col cols="12" lg="3"> </v-col>
       <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/company/add/all')"
        >
          <v-icon dark size="30"> mdi-domain </v-icon>
          <h2>&nbsp;회사 일괄등록</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/product/add/all')"
        >
          <v-icon dark size="30"> mdi-archive-cog </v-icon>
          <h2>&nbsp;제품 일괄등록</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/contract/add/all')"
        >
          <v-icon dark size="30"> mdi-briefcase </v-icon>
          <h2>&nbsp;계약 일괄등록</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3"> </v-col>
      <v-col cols="12" lg="3"> </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/product/version/add/all')"
        >
          <v-icon dark size="30"> mdi-archive-cog </v-icon>
          <h2>&nbsp;버전 일괄등록</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3"> </v-col> -->
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/charts')"
        >
          <v-icon dark size="30"> mdi-chart-bubble </v-icon>
          <h2>&nbsp;통계</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/report')"
        >
          <v-icon dark size="30"> mdi-chart-bubble </v-icon>
          <h2>&nbsp;레포팅</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/system')"
        >
          <v-icon dark size="30"> mdi-cog </v-icon>
          <h2>&nbsp;시스템 설정</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/bugreport')"
        >
          <v-icon dark size="30"> mdi-bug </v-icon>
          <h2>&nbsp;버그리포트</h2>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/case')"
        >
          <v-icon dark size="30"> mdi-note </v-icon>
          <h2>&nbsp;케이스 관리</h2>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
          class="pa-12"
          color="#FF4D00"
          dark
          block
          @click="$router.push('/admin/data')"
        >
          <v-icon dark size="30"> mdi-note </v-icon>
          <h2>&nbsp;데이터 추출</h2>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    devnow() {
      alert("개발 진행중입니다.\n2022-04-04 오후 19시 업데이트 예정입니다.");
    },
  },
};
</script>
